@import '../../App.scss';

.todo-app-nav-item {
  @extend .m-2, .p-1;
  border-radius: 0.5rem;
  background-color: $gray-700;

  // a tag Comes from react-router-dom Link
  a,
  button {
    @extend .fs-4, .text-decoration-none, .text-white;
    font-weight: 600;
    background-color: $gray-700;
    border: none;
    color: $white;
  }
}
