@import '../../App.scss';

.task-name-item {
  background-color: $gray-700;

  .task-name {
    text-align: left;

    p {
      @extend .fs-3, .my-0, .ms-2;
      color: $white;
    }
  }

  .task-name-delete-button {
    @extend .d-flex, .align-items-center;
    height: 100%;

    button {
      background-color: red;
      border: none;
    }
  }
}
