@import '../../App.scss';

.todo-app-card {
  @extend .card, .shadow-lg;
  height: 100%;

  &.dark {
    @extend .text-white, .bg-dark;
  }

  &.light {
    @extend .text-black, .bg-light;
  }

  .todo-app-card-body {
    height: 100%;
  }
}
