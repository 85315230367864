@import '../../App.scss';

.list-name-item {
  .list-name {
    text-align: left;

    button {
      @extend .fs-5;
      background-color: transparent;
    }
  }

  .list-name-delete-button {
    @extend .d-flex, .align-items-center;
    height: 100%;

    button {
      background-color: red;
      border: none;
    }
  }
}
