@import '../../App.scss';

.landing-page-top {
  background-color: $blue-900;
}

.landing-page-bottom {
  background-color: $cyan-900;
}

.landing-page-card-list {
  @extend .list-unstyled, .d-flex, .flex-column, .justify-content-evenly;
  height: 100%;

  li {
    @extend .my-4, .fs-3, .text-center;
  }
}
