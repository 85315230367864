@import '../../App.scss';

.main-section-container {
  @extend .bg-gradient, .py-5;

  &.dark-blue {
    background-color: $blue-900;
  }

  &.dark-cyan {
    background-color: $cyan-900;
  }

  &.white {
    background-color: $white;
  }

  &.full-height {
    height: 100%;
  }
}
