@import '~bootstrap/scss/bootstrap';

html,
body,
#root,
#root > div {
  height: 100%;
}

.under-nav {
  height: 100%;
}
